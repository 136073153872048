<template>
  <main>
    <p>
      I'm a fullstack javascript engineer with a focus on frontend development
      and building user interfaces
      <br />
      <br />
      I write my code majorly in javascript/typescript and use frameworks like
      react and vue extensively for creating frontend application logic.
      <br />
      <br />
      My API's are written in node.js and I use noSQL and SQL based databases
      (mongoDB and postgresql respectively)
    </p>
    <p>
      When I'm not deep in work related issues, i usually read about start-ups
      <a href="https://techcrunch.com/" target="_blank">here 📈</a> and watch
      movies <a href="https://netflix.com" target="_blank">here 🎬</a>, and a
      huge fan of coldplay, even made a project on them
      <a href="https://arinze19.github.io/colddrumkit.html" target="_blank"
        >here</a
      >. I contribute and give back to community using
      <a href="https://kwanda.co" target="_blank">Kwanda</a>.
    </p>
    <br />
    <br />
    <p>
      If you have a question or want to collaborate on a project, feel free to
      <a href="mailto:arinze19@outlook.com">Email me 📫</a>
    </p>
  </main>
</template>

<style scoped>
main {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 50%;
  margin: 0 auto;
  max-width: 900px;
  line-height: 1.5;
}

p {
  padding-bottom: 10px;
  font-size: 25px;
}

h2 {
  font-size: 45px;
}

@media (max-width: 768px) {
  p {
    font-size: 16px;
  }

  main {
    width: 80%;
  }
}
</style>
